<template>
  <div v-if="!isAccepted" :class="['trustbox', `p-${position}`, { 'is-visible': visible }]">
    <div>
      <div :class="['trustbox-bg', `bg-${bgVariant}`]"></div>

      <div class="trustbox-content container">
        <div :class="['trustbox-inner', `text-${textColor}`]">
          <slot :close="close"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { name } from '@/../package.json'

import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

const FADEIN_DELAY_SECONDS = 1
const STORAGE_KEY = `${name.toUpperCase()}_TRUSTED`
const THEME_COLOR_KEYS = Object.keys(SCSS_VARIABLES.colors.theme)

export default {
  name: 'TrustBox',
  props: {
    position: {
      type: String,
      default: 'bottom',
      validator: value => ['top', 'bottom', 'left', 'right'].includes(value)
    },
    bgVariant: {
      type: String,
      default: 'dark',
      validator: value => THEME_COLOR_KEYS.includes(value)
    },
    textColor: {
      type: String,
      default: 'white',
      validator: value => ['white', 'black'].includes(value)
    }
  },
  data () {
    return {
      isAccepted: window.localStorage.getItem(STORAGE_KEY),
      visible: false
    }
  },
  methods: {
    toggle (state = null) {
      const newState = state !== null ? state : !this.visible

      if (newState) {
        setTimeout(() => {
          this.visible = newState
        }, FADEIN_DELAY_SECONDS * 1000)
      } else {
        this.visible = newState
      }
    },
    close () {
      this.toggle(false)
      window.localStorage.setItem(STORAGE_KEY, true)
    }
  },
  created () {
    if (!this.isAccepted) {
      this.toggle(true)
    }
  }
}
</script>

<style lang="scss">
$trustbox-transition-in: all ease-out 1000ms !default;
$trustbox-transition-out: all ease-in 300ms !default;

$trustbox-bg-opacity: 0.95 !default;

$trustbox-inner-padding-y: $component-padding-y * 0.5 !default;
$trustbox-inner-padding-x: 0 !default;
$trustbox-inner-font-size: $font-size-sm !default;

.trustbox {
  position: fixed;
  z-index: 10000;

  > div {
    transition: $trustbox-transition-out;
  }

  .trustbox-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: $trustbox-bg-opacity;
    z-index: 1;
  }

  .trustbox-content {
    position: relative;
    z-index: 2;

    .trustbox-inner {
      padding: $trustbox-inner-padding-y $trustbox-inner-padding-x;
      font-size: $trustbox-inner-font-size;
    }
  }

  &.p-top,
  &.p-bottom {
    left: 0;
    right: 0;
  }

  &.p-left,
  &.p-right {
    top: 0;
    bottom: 0;

    > div {
      height: 100%;
    }
  }

  &.p-top { bottom: 100%; }
  &.p-bottom { top: 100%; }
  &.p-left { right: 100%; }
  &.p-right { left: 100%; }

  &.is-visible {
    > div {
      transition: $trustbox-transition-in;
    }

    &.p-top > div { transform: translateY(100%); }
    &.p-bottom > div { transform: translateY(-100%); }
    &.p-left > div { transform: translateX(100%); }
    &.p-right > div { transform: translateX(-100%); }
  }
}
</style>
